/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/

// Grid specific:
$grid: 12;
$gutter: 30px;
$gutter_sm: 30px;
$gutter_md: 30px;
$gutter_lg: 30px;
$gutter_xlg: 30px;
$gutter_xxlg: 30px;


// Grid & Layout breakpoints:
$container-max-width: 1440px;

$xs: 479px;
$sm: 480px;
$md: 768px;
$lg: 980px;
$xlg: 1200px;
$xxlg: 1366px;
$xxxlg: 1900px;


// Širina ekrana na kojoj se preslaguju column_content i column_sidebar
$column_break: 980px;




/**************************************************
    FONTS
    **********************************************/

$font_prim: 'Montserrat', sans-serif;
$font_sec: 'Exo', sans-serif;



$base-fontSize: 62.5%;
$base-lineHeight: 1.5;


// Font weights
$thin: 100;
$extra_light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;
$heavy: 900;

/**************************************************
    COLORS
    **********************************************/

// base colors
$blue: #3454D1;
$blue_dark: #4D78F1;
$blue_light: #AECAE9;
$blue_sky: #F2F8FE;
$gray_dark: #D9D9D9;
$gray: #F3F3F3;
$gray_light: #F9F9F9;
$orange:#F37021;
$text: #666666;
